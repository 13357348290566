import React from "react"
import styled from "styled-components"
import CloudflareImage from "../reusable-components/cloudflare-image"

import Tabs from "../reusable-components/layout/tabs"
import ProductReviewsTabContent from "./product-reviews-tab-content"
import TabContentLayout from "./tab-content-layout"
import SpecsTabContent from "./specs-tab-content"

import { getProperty } from "../../lib/utils"

const ProductTabsSection = ({
  product,
  productId,
  colour,
  finish,
  material,
  productReviews = [],
}) => {
  const specsACF = getProperty(
    product,
    ["acf_product_information", "productSpecifications"],
    ""
  )
  const tdImage = getProperty(
    product,
    [
      "acf_product_information",
      "productTechnicalDrawing",
      "tdImage",
      "sourceUrl",
    ],
    ""
  )

  const tdFile =
    product?.acf_product_information?.productTechnicalDrawing?.tdFile?.localFile
      ?.publicURL ?? ""

  const tabsContent = [
    {
      tabHeading: "Shipping Dimensions",
      tabContent: (
        <TabContentLayout
          content={
            <SpecsTabContent
              product={product}
              colour={colour}
              finish={finish}
              material={material}
              specsACF={specsACF}
              pdfURL={tdFile}
            />
          }
        />
      ),
    },
    {
      tabHeading: "Technical Drawing",
      tabContent: (
        <TabContentLayout
          content={
            <>
              {tdFile && (
                <a
                  style={{ marginBottom: "15px", fontWeight: "bold" }}
                  target="_blank"
                  rel="noreferrer"
                  href={tdFile}
                >
                  {`PDF Drawing: 
                    ${tdFile.substring(tdFile.lastIndexOf("/") + 1)}`}
                </a>
              )}
              {tdImage && <CloudflareImage src={tdImage} objectFit="cover" />}
              {!tdFile && !tdImage && (
                <p>There is no techincal drawing available for this product</p>
              )}
            </>
          }
        />
      ),
    },
    {
      tabHeading: "Reviews",
      tabContent: (
        <TabContentLayout
          content={
            <ProductReviewsTabContent
              productId={productId}
              productReviews={productReviews}
            />
          }
        />
      ),
    },
  ]

  return <StyledTabs tabsContentArray={tabsContent} />
}

export default ProductTabsSection

// ===============
//     STYLES
// ===============
const StyledTabs = styled(Tabs)`
  .tab-nav-wrapper {
    margin: 0 1.875rem;
    justify-content: flex-start;
    font-size: 1.75rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.grey2};
    line-height: 2.5;
    text-transform: uppercase;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin: 0;
    }
    > a {
      margin-right: 100px;
      @media (max-width: ${({ theme }) => theme.breakMedium}) {
        width: 100%;
        text-align: center;
        margin-right: 0;
      }
    }
    .tab-active {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
  div.tab-active {
    flex-wrap: wrap;
  }
  .cloudflare-image-wrapper {
    margin: 0 1.875rem;
    width: 100%;
    @media (max-width: ${({ theme }) => theme.breakSmall}) {
      margin: 0;
    }
  }
`
